<template>
  <div id="print" style="color:black;">
    <!-- 标题 -->
    <p class="title">上海奥吉实业有限公司</p>
    <p class="title">销售代销单</p>

    <!-- 信息 -->
    <div style="margin-bottom: 10px;">
      <a-row>
        <a-col :span="8"></a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          代销号：{{ orderData.agent_num }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          购买单位：{{ orderData.buyer_name }}
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          代销日期：{{ orderData.agent_date }}
        </a-col>
      </a-row>
    </div>

    <!-- 表格 -->
    <div>
      <table style="border-color: #000;">
        <thead>
          <tr>
            <th style="border-color: #000;">序号</th>
            <th style="border-color: #000;">编码</th>
            <th style="border-color: #000;">品名</th>
            <th style="border-color: #000;">规格</th>
            <th style="border-color: #000;">单位</th>
            <!-- <th>进货单价</th> -->
            <th style="border-color: #000;">出货数量</th>
            <th style="border-color: #000;">出货单价</th>
            <th style="border-color: #000;">出货金额</th>
            <th style="border-color: #000;">备注</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in orderList" :key="index">
            <td style="border-color: #000;">{{ item.index }}</td>
            <td style="border-color: #000;">{{ item.good_no }}</td>
            <td style="border-color: #000;">{{ item.good_name }}</td>
            <td style="border-color: #000;">{{ item.good_spec }}</td>
            <td style="border-color: #000;">{{ item.unit }}</td>
            <!-- <td>{{ item.price_in }}</td> -->
            <td style="border-color: #000;">{{ item.out_num }}</td>
            <td style="border-color: #000;">{{ item.price_out }}</td>
            <td style="border-color: #000;">{{ item.sum_price_out }}</td>
            <td style="border-color: #000;">{{ item.remark }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 底部 -->
    <p style="margin-top: 10px;">
      <a-row style="margin-top: 10px;">
        <a-col :span="6"><span class="bottomStyle">收货人：</span>{{ orderData.receiving_person }}</a-col>
        <a-col :span="6"><span class="bottomStyle">联系电话：</span>{{ orderData.receiving_phone }}</a-col>
        <a-col :span="12"><span class="bottomStyle">收货地址：</span>{{ orderData.receiving_address_name }}</a-col>
      </a-row>
      <a-row style="margin-top: 10px;">
        <a-col :span="6">
          <span class="bottomStyle">交易号：</span>
          <span v-for="(obj, index) in orderData.cash_record_list" :key="index">
            {{ obj.trx_id }}
          </span>
        </a-col>
        <a-col :span="6">
          <span class="bottomStyle">交易时间：</span>
          <span v-for="(obj, index) in orderData.cash_record_list" :key="index">
            {{ obj.pay_date }}
          </span>
        </a-col>
        <a-col :span="12"><span class="bottomStyle">备注：</span>{{ orderData.remark }}</a-col>
      </a-row>
    </p>
  </div>
</template>

<script>
export default {
  name: `SalePurchaseSheetBill`,
  props: {
    currentData: { type: Object, default: null },
    balanceData: { type: Array, default: null }
  },
  created() {
    this.initData();
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val;
    },
    balanceData: {
      handler(val, oldVal) {
        this.initData();
      },
      deep: true
    }
  },
  data() {
    return {
      orderData: {},
      orderList: []
    }
  },
  methods: {
    initData() {
      // 初始化数据
      this.orderData = this.currentData;
      this.orderList = this.balanceData;
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}

@media print {
  html, body {
    height: inherit;
  }
}

.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}

#print { width: 80%; margin: 0 auto; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
.title { text-align: center; font-weight: bold; }
.bottomStyle { font-weight: bold; }
</style>
